import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'
import './view-more-section.component.scss'

const ViewMoreSectionComponent = props => {
  return (
    <div className={props.viewMoreContent.className} style={{ backgroundColor: props.viewMoreContent.background }}>
      <div className="primary-card">
        <img className="image-icon" src={props.viewMoreContent.icon} alt={props.viewMoreContent.alt} />
        <img className="image-icon-mobile" src={props.viewMoreContent.mobileIcon} alt={props.viewMoreContent.alt} />
        <SiteLink className="detail-comment" tracking={props.viewMoreContent.tracking} to={props.viewMoreContent.to}>
          {props.viewMoreContent.content}&nbsp;
          <FontAwesomeIcon className="arrow-right" icon="angle-right" />
        </SiteLink>
      </div>
    </div>
  )
}

ViewMoreSectionComponent.propTypes = {
  viewMoreContent: PropTypes.element.isRequired
}

export default ViewMoreSectionComponent
