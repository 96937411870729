import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import SiteLink from '@components/SiteLink'
import ViewMoreSectionComponent from '@components/view-more/view-more-section.component'
import ReferenceComponent from '@components/references/reference.component'
import sleepSpecialist from '@assets/images/locate-sleep-specialist.jpg'
import iconScreen from '@assets/images/screen-narcolepsy-symptoms-icon-white.png'
import iconDiagnose from '@assets/images/diagnose-narcolepsy-icon-white.png'
import iconScreenMobile from '@assets/images/screen-narcolepsy-symptoms-icon-mobile.png'
import iconDiagnoseMobile from '@assets/images/diagnose-narcolepsy-icon-mobile.png'
import './refer-sleep-specialist.scss'

const ReferSleepSpecialist = ({ location }) => {
  const references = [
    {
      content:
        'Carter LP, Acebo C, Kim A. Patients’ journeys to a narcolepsy diagnosis: a physician survey and retrospective chart review. ',
      source: 'Postgrad Med. ',
      info: '2014;126(3):216-224.'
    },
    {
      content: 'Thorpy MJ, Krieger AC. Delayed diagnosis of narcolepsy: characterization and impact. ',
      source: 'Sleep Med. ',
      info: '2014;15(5):502-507.'
    },
    {
      content:
        'Thorpy M, Black J, Gow M, et al. Demographic and diagnosis experience of people with narcolepsy: first wave results from the Nexus narcolepsy registry. 30th Annual Meeting of the Associated Professional Sleep Societies, June 11-15, 2016; Denver, CO. Poster 326.',
      source: '',
      info: ''
    },
    {
      content: 'Green PM, Stillman MJ. Narcolepsy. Signs, symptoms, differential diagnosis, and management. ',
      source: 'Arch Fam Med. ',
      info: '1998;7(5):472-478.'
    },
    {
      content:
        'Johns M, Hocking B. Excessive daytime sleepiness: daytime sleepiness and sleep habits of Australian workers. ',
      source: 'Sleep. ',
      info: '1997;20(10):844-849.'
    }
  ]
  const screenViewMore = {
    className: 'view-more-detail',
    content: 'Screen your patients for narcolepsy symptoms',
    icon: iconScreen,
    mobileIcon: iconScreenMobile,
    alt: 'Screen for narcolepsy symptoms icon',
    to: '/screening-and-diagnosis/screeners/',
    background: '#0096d6',
    tracking: 'referring patients,	click,	screening tools tile - refer'
  }
  const diagnoseiewMore = {
    className: 'view-more-detail',
    content: 'Learn more about diagnostic testing for narcolepsy',
    icon: iconDiagnose,
    mobileIcon: iconDiagnoseMobile,
    alt: 'Diagnose narcolepsy with a clinical interview and sleep laboratory tests icon',
    to: '/screening-and-diagnosis/diagnostic-process/',
    background: '#666666',
    tracking: 'referring patients,	click,	diagnostic testing tile - refer'
  }
  return (
    <Layout location={location}>
      <div className="page-wrapper">
        <div className="primary-container when-where-sleep-specialist">
          <div className="sleep-specialist">
            <h1 className="main-page-header">Referring Patients to a Sleep Specialist</h1>
            <p className="sleep-specialist-content">
              People with narcolepsy can often suffer with their symptoms for many years and see multiple healthcare
              providers before receiving an accurate diagnosis.<sup>1-3</sup>
            </p>
            <p className="sleep-specialist-content">
              All patients who present with excessive daytime sleepiness (Epworth Sleepiness Scale score &gt;10) and other
              symptoms of narcolepsy should be referred to a sleep specialist for a clinical interview and in-lab sleep
              studies.<sup>4,5</sup> An early referral can facilitate the appropriate diagnosis.
            </p>
          </div>
          <div className="primary-card image-card">
            <div className="image-content">
              <div className="tertiary-header">
                Refer your patients with high levels of daytime sleepiness to a sleep specialist.
              </div>
              <SiteLink
                tracking="referring patients,	click,	locate sleep specialist - refer"
                className="button button-text"
                to="/stay-connected/locate-a-sleep-specialist/"
              >
                Locate a Nearby Sleep Specialist <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </SiteLink>
            </div>
            <img className="sleep-specialist-image" src={sleepSpecialist} alt="Locate a sleep specialist" />
          </div>
        </div>
        <div className="primary-container view-more-section">
          <ViewMoreSectionComponent viewMoreContent={screenViewMore} />
          <ViewMoreSectionComponent viewMoreContent={diagnoseiewMore} />
        </div>
        <ReferenceComponent referenceList={references} />
      </div>
    </Layout>
  )
}

export default ReferSleepSpecialist
